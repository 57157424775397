import React, { useEffect, useState } from 'react';
import {Redirect, Switch, useHistory} from 'react-router-dom';
import Route from './Route';
import Pacientes from '../pages/Pacientes/Pacientes';
import Patients from '../pages/Pacientes/v2';
import Historico from '../pages/Historico/index';
import Login from '../pages/Auth/Login/index';
import Forbidden from '../pages/Forbidden/index';

import { useKeycloak } from '@react-keycloak/web';
import { kcAuth } from '../keycloak';

// Pages
import Dashboard from '../pages/Dashboard/v2';
import TimelinePage from '../pages/Timeline/Timeline';
import SettingsPage from '../pages/Settings';
import ChangelogPage from '../pages/Changelog';
import HealthPage from '../pages/Health';
import PatientEvolution from '../pages/PatientEvolution/PatientEvolution';
import History from '../pages/Historico/v2/History';
import { HIDE_FROM } from '../pages/hideAnonymousEvaluation';
import {useGetLoggedUserCompaniesQuery} from "../services/companies/companies.service";

const paths = {
  health: "/health",
  settings: "/settings",
  dashboard: "/dashboard",
  patients: "/pacientes",
  history: "/historico",
  patient: "/paciente/:id",
  evolution: "/paciente/:id/evolucao-completa",
  changelog: "/changelog",
  forbidden: "/nao-permitido"
}

export default function Routes() {
  const [keycloak, initialized] = useKeycloak();
  const [userName, setUserName] = useState(localStorage.getItem('username'));
  const history = useHistory();

  const {data: companiesFetched, isLoading: companiesLoading, error} = useGetLoggedUserCompaniesQuery();

  useEffect(() => {
    if (error) {
      history.push("/nao-permitido");
    }
  }, [error]);

  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        // Quando autenticado, define o username no localstorage
        kcAuth();
      } else {
        // Quando não autenticado, força o login em TODAS as rotas.
        keycloak.login();
      }
    }
  }, [keycloak.authenticated, initialized, history, keycloak]);

  if (!initialized) return null; // <h2 style={{ paddingLeft: 109, paddingTop: 20 }}>Loading...</h2>;

  return (
    <Switch>
      <Route path="/" exact resource={false} component={Login} />
      <Route
        path={paths.health}
        exact
        resource={false}
        component={HealthPage}
      />
      {!HIDE_FROM.includes(userName) && (
        <Route
          path={paths.settings}
          exact
          resource={false}
          component={SettingsPage}
        />
      )}
      <Route
        path={paths.dashboard}
        exact
        resource={false}
        component={Dashboard}
      />
      <Route
        path={paths.patients}
        exact
        resource={false}
        component={Patients}
      />
      <Route
        path={paths.history}
        exact
        resource={false}
        component={History}
      />
      <Route
        path={paths.forbidden}
        exact
        resource={false}
        component={Forbidden}
      />
      <Route
        path={paths.patient}
        exact
        resource={false}
        component={TimelinePage}
      />
      <Route
        path={paths.evolution}
        exact
        resource={false}
        component={PatientEvolution}
      />
      <Route
        path={paths.changelog}
        exact
        resource={false}
        component={ChangelogPage}
      />
      <Redirect from="*" to="/dashboard"></Redirect>
    </Switch>
  );
}
