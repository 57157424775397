import React, { useEffect } from 'react';
import { isaTheme } from './styles/isa.theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Routes from './routes';
import {BrowserRouter} from 'react-router-dom';

import {KeycloakProvider, useKeycloak} from '@react-keycloak/web';
import { keycloakConnect, keycloakProviderInitConfig } from './keycloak';

import { useHistory } from 'react-router-dom';
import GlobalStyle from './styles/global';
import IconMenu from './components/molecules/IconMenu/index';
// import { AuthService } from './services/api/auth/auth.service';
import { connect } from 'react-redux';
import { infectionTypes } from './actions/InfectionTypesActions';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import {useGetLoggedUserCompaniesQuery} from "./services/companies/companies.service";


function App(props) {
  const { getInfectionTypes } = props;
  const [keycloak, initialized] = useKeycloak();

  let history = useHistory();

  const {data: companiesFetched, isLoading: companiesLoading, error} = useGetLoggedUserCompaniesQuery();

  useEffect(() => {
    // if (AuthService.isAuthenticated()) {
    if(initialized) {
      getInfectionTypes();
    }
  }, []);


  const appComponents = () => {
    // if (AuthService.isAuthenticated()) {
      return ;
    // } else {
    //   AuthService.authenticate(history);
    //   return <></>;
    // }
  };

  return (
    <>
      <MuiPickersUtilsProvider locale='pt-br' utils={MomentUtils}>
        <KeycloakProvider keycloak={keycloakConnect} onInit={keycloakProviderInitConfig}>
          <ThemeProvider theme={isaTheme}>
            <BrowserRouter>
              <IconMenu />
              <Routes />
              <GlobalStyle />
            </BrowserRouter>
            <div id="snackbars" />
          </ThemeProvider>
        </KeycloakProvider>
      </MuiPickersUtilsProvider>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getInfectionTypes: () => dispatch(infectionTypes()),
});

export default connect(null, mapDispatchToProps)(App);
